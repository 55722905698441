var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "itemTable",
            attrs: {
              title: "체크리스트 항목별 개선목록",
              tableId: "checklistGrid",
              columnSetting: false,
              usePaging: false,
              filtering: false,
              hideBottom: true,
              columns: _vm.itemTable.columns,
              data: _vm.itemTable.data,
              merge: _vm.itemTable.merge,
              gridHeight: _vm.gridheight,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                      ? _vm._l(
                          _vm.$comm.ibmTagItems(props.row, _vm.colorItems),
                          function (item, index) {
                            return _c(
                              "q-chip",
                              {
                                key: index,
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                },
                                attrs: {
                                  color:
                                    item.ibmClassCd === "IC00000001"
                                      ? "light-blue"
                                      : "red-6",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(item, props.row)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(item.title) + " "),
                                _c("c-assess-tooltip", {
                                  attrs: {
                                    ibmTooltip: item.ibmTooltip,
                                    ibmClassCd: item.ibmClassCd,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        )
                      : void 0,
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "tableImpr",
              attrs: {
                title: "항목외 개선 목록",
                columns: _vm.gridImpr.columns,
                editable: _vm.disabled,
                data: _vm.result.equipmentImproveModels,
                merge: _vm.gridImpr.merge,
                selection: "multiple",
                rowKey: "equipImproveId",
                usePaging: false,
                isExcelDown: false,
                filtering: false,
                columnSetting: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      props.row["sopImprovementIds"] &&
                      props.row["ibmStepNames"]
                        ? _vm._l(
                            _vm.$comm.ibmTagItems(props.row, _vm.colorItems),
                            function (item, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    color:
                                      item.ibmClassCd === "IC00000001"
                                        ? "blue-6"
                                        : "orange",
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClick(item, props.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.title) + " "),
                                  _c("c-assess-tooltip", {
                                    attrs: {
                                      ibmTooltip: item.ibmTooltip,
                                      ibmClassCd: item.ibmClassCd,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                      props.row.editFlag !== "C"
                        ? _c(
                            "div",
                            [
                              _c(
                                "q-btn-group",
                                {
                                  staticClass: "ColumInnerBtnGroup",
                                  attrs: { outline: "" },
                                },
                                [
                                  _vm.disabled
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "ColumInnerBtn",
                                          attrs: {
                                            icon: "add",
                                            color: "blue-6",
                                            "text-color": "white",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.innerBtnClicked(
                                                col,
                                                props,
                                                "1"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _vm._v(" 개선요청등록 "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.disabled
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "ColumInnerBtn",
                                          attrs: {
                                            icon: "add",
                                            color: "orange",
                                            "text-color": "white",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.innerBtnClicked(
                                                col,
                                                props,
                                                "2"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _vm._v(" 즉시조치등록 "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.disabled &&
                      _vm.result.equipmentImproveModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                      _vm.disabled &&
                      _vm.result.noPlanResultEnrollFlag !== "Y" &&
                      _vm.isMultiple &&
                      _vm.result.equipmentImproveModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.insertUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.result.equipmentImproveModels,
                              mappingType: "POST",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveImprove,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isMultiple
                ? _c(
                    "template",
                    { slot: "table-chip" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c(
                            "q-chip",
                            [
                              _c("q-avatar", {
                                attrs: {
                                  icon: "push_pin",
                                  color: "blue-6",
                                  "text-color": "white",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "설비명(관리번호) : " +
                                      _vm.result.equipmentName +
                                      "(" +
                                      _vm.result.equipmentCd +
                                      ")"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }